export default {
  badge: 'Badge',
  open: 'Open',
  close: 'Fermer',
  dataIterator: {
    noResultsText: 'Aucun enregistrement correspondant trouvé',
    loadingText: `Chargement de l'élément...`,
  },
  dataTable: {
    itemsPerPageText: 'Lignes par page :',
    ariaLabel: {
      sortDescending: 'Tri décroissant.',
      sortAscending: 'Tri croissant.',
      sortNone: 'Non trié.',
      activateNone: 'Activer pour supprimer le tri.',
      activateDescending: 'Activer pour trier par ordre décroissant.',
      activateAscending: 'Activer pour trier par ordre croissant.',
    },
    sortBy: 'Trier par',
  },
  dataFooter: {
    itemsPerPageText: 'Élements par page :',
    itemsPerPageAll: 'Tous',
    nextPage: 'Page suivante',
    prevPage: 'Page précédente',
    firstPage: 'Première page',
    lastPage: 'Dernière page',
    pageText: '{0}-{1} de {2}',
  },
  dateRangeInput: {
    divider: 'to',
  },
  datePicker: {
    ok: 'OK',
    cancel: 'Cancel',
    range: {
      title: 'Select dates',
      header: 'Enter dates',
    },
    title: 'Select date',
    header: 'Enter date',
    input: {
      placeholder: 'Enter date',
    },
  },
  noDataText: 'Aucune donnée disponible',
  carousel: {
    prev: 'Visuel précédent',
    next: 'Visuel suivant',
    ariaLabel: {
      delimiter: 'Diapositive {0} de {1}',
    },
  },
  calendar: {
    moreEvents: '{0} de plus',
  },
  input: {
    clear: 'Clear {0}',
    prependAction: '{0} prepended action',
    appendAction: '{0} appended action',
    otp: 'Please enter OTP character {0}',
  },
  fileInput: {
    counter: '{0} fichier(s)',
    counterSize: '{0} fichier(s) ({1} au total)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      root: 'Navigation de pagination',
      next: 'Page suivante',
      previous: 'Page précédente',
      page: 'Aller à la page {0}',
      currentPage: 'Page actuelle, Page {0}',
      first: 'First page',
      last: 'Last page',
    },
  },
  stepper: {
    next: 'Next',
    prev: 'Previous',
  },
  rating: {
    ariaLabel: {
      item: 'Note de {0} sur {1}',
    },
  },
  loading: 'Loading...',
  infiniteScroll: {
    loadMore: 'Load more',
    empty: 'No more',
  },
}
